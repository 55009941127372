<template>
	<div class="register" style="    min-height: 548px;">
		<div class="box-card">
			<el-tabs v-model="activeName" @tab-click="handleClick">
				<el-tab-pane label="รหัสผ่าน" name="first" style="    font-weight: 800;">
					<el-form v-if="activeName == 'first'" :model="registerForm" :rules="registerRules" ref="registerRef" label-width="120px"
					 label-position="right" show-message style="    margin-top: 15px;">
						<el-form-item label="ชื่อผู้ใช้" prop="username" style="    font-weight: 700;">
							<el-input v-model="registerForm.username" placeholder="กรุณาใส่ชื่อผู้ใช้"></el-input>
						</el-form-item>
						<el-form-item label="รหัสผ่าน" prop="password" style="    font-weight: 700;">
							<el-input v-model="registerForm.password" placeholder="กรุณาใส่รหัสผ่าน" type="password"></el-input>
						</el-form-item>
						<el-form-item label="ยืนยันรหัสผ่าน" prop="checkPass" style="    font-weight: 700;">
							<el-input v-model="registerForm.checkPass" placeholder="โปรดป้อนรหัสผ่านยืนยัน" type="password"></el-input>
						</el-form-item>
						<el-form-item label="รหัสยืนยัน" prop="code" style="    font-weight: 700;">
							<el-input v-model="registerForm.code" placeholder="กรุณากรอกรหัสยืนยัน" maxlength="4" >
								<template slot="append" style="    width: 100px;
    padding: 0 0px;">
									<img :src="captcha.img" mode class="captcha" @click="getCode" style="    width: 100px;
    padding: 0 0px;" />
								</template>
							</el-input>
						</el-form-item>
					</el-form>
					<div class="xy" @click="check">
						<div class="xy-wrap">
							<div class="iconfont" :class="ischecked ? 'icon-xuanze-duoxuan' : 'icon-xuanze'"></div>
							<div class="content">
								ยืนยัน
								<b @click.stop="getAggrement">《ข้อตกลงการให้บริการ》</b>
							</div>
						</div>
						<div class="toLogin" @click="toLogin">ลงชื่อเข้าใช้ตอนนี้</div>
					</div>
					<el-button @click="register" style="    font-weight: 800;
    font-family: sukhumvittadmai2;">ลงทะเบียนตอนนี้</el-button>
				</el-tab-pane>

				<el-tab-pane label="เบอร์มือถือ" name="second" v-if="registerConfig.register.indexOf('mobile') != -1">
					<el-form v-if="activeName == 'second'" :model="registerForm" :rules="mobileRules" ref="mobileRuleForm"  style="    margin-top: 28px;">
						<el-form-item prop="mobile">
							<el-input v-model="registerForm.mobile" placeholder="กรุณากรอกหมายเลขโทรศัพท์ของคุณ">
								<template slot="prepend">
									<i class="iconfont icon-shouji-copy"></i>
								</template>
							</el-input>
						</el-form-item>

						<el-form-item prop="vercode">
							<el-input v-model="registerForm.vercode" autocomplete="off" placeholder="กรุณากรอกรหัสยืนยัน" maxlength="4">
								<template slot="prepend">
									<i class="iconfont icon-yanzhengma"></i>
								</template>
								<template slot="append" >
									<img :src="captcha.img" mode class="captcha" @click="getCode" style="    width: 100px;
    padding: 0 0px;" />
								</template>
							</el-input>
						</el-form-item>

						<el-form-item prop="dynacode">
							<el-input v-model="registerForm.dynacode" maxlength="4" placeholder="กรุณาใส่รหัส SMS OTP">
								<template slot="prepend">
									<i class="iconfont icon-dongtaima"></i>
								</template>
								<template slot="append">
									<div class="dynacode" :class="dynacodeData.seconds == 120 ? 'ns-text-color' : 'ns-text-color-gray'" @click="sendMobileCode('mobileRuleForm')">
										{{ dynacodeData.codeText }}
									</div>
								</template>
							</el-input>
						</el-form-item>


					</el-form>
					<div class="xy" @click="check">
						<div class="xy-wrap">
							<div class="iconfont" :class="ischecked ? 'icon-xuanze-duoxuan' : 'icon-xuanze'"></div>
							<div class="content">
								ยืนยัน
								<b @click.stop="getAggrement">《ข้อตกลงการให้บริการ》</b>
							</div>
						</div>
						<div class="toLogin" @click="toLogin">ลงชื่อเข้าใช้ตอนนี้</div>
					</div>

					<el-button @click="registerMobile" style="    font-weight: 800;
    font-family: sukhumvittadmai2;">ลงทะเบียนตอนนี้</el-button>
				</el-tab-pane>
			</el-tabs>
			<el-dialog :title="agreement.title" :visible.sync="aggrementVisible" width="60%" :before-close="aggrementClose"
			 :lock-scroll="false" center>
				<div v-html="agreement.content" class="xyContent"></div>
			</el-dialog>
		</div>
		<!-- พื้นที่ลอยน้ํา -->
		<div class="floatLayer-wrap" v-show="is_show && reward" :style="{width:bgWidth,height:bgHeight}">
			<div class="reward-wrap">
				<img :src="$util.img('upload/uniapp/register_reward_img.png')" mode="widthFix" class="bg-img-head"></img>
				<img :src="$util.img('upload/uniapp/register_reward_money.png')" mode="widthFix" class="bg-img-money"></img>
				<img :src="$util.img('upload/uniapp/register_reward_head.png?15')" mode="widthFix" class="bg-img" style="    background-size: 102.7% 101%;
    background-repeat: no-repeat;
    background-position-x: -0.9vw;"></img>
				<div class="wrap" style="  width: 437.8px;
    scale: 1;
    margin-left: -21.56px;
    margin-top: -25px;
    padding: 0px 5px 15px;
    background: url(https://shop.phawhale.com/public/uniapp/register_reward/register_reward_head15.png) 0% 0% / cover no-repeat; ">
					<div style="        height: 472px;  ">
						<div class="reward-content" style="height: 23vw;
    scale: 0.9;
    transform: translateX(0px);
    transform: translatey(-19px);">
							<div class="reward-item" v-if="reward && reward.point > 0">
								<div class="head">รางวัลคะแนน</div>
								<div class="content">
									<div class="info">
										<div>
											<span class="num">{{ reward.point }}</span>
											<span class="type">คะแนน</span>
										</div>
										<div class="desc">ใช้แลกเปลียนสินค้าได้</div>
									</div>
									<div class="tip" @click="closeRewardPopup('point')">ตรวจสอบ</div>
								</div>
							</div>
							<div class="reward-item" v-if="reward && reward.growth > 0">
								<div class="head">มูลค่าการเติบโต</div>
								<div class="content">
									<div class="info">
										<div>
											<span class="num">{{ reward.growth }}</span>
											<span class="type">มูลค่าการเติบโต</span>
										</div>
										<div class="desc">ใช้เพื่อเพิ่มระดับการเป็นสมาชิก</div>
									</div>
									<div class="tip" @click="closeRewardPopup('growth')">ตรวจสอบ</div>
								</div>
							</div>
							<div class="reward-item" v-if="reward && reward.balance > 0">
								<div class="head">รางวันซองเงินสด</div>
								<div class="content">
									<div class="info">
										<div>
											<span class="num">{{ reward.balance }}</span>
											<span class="type"> บาท</span>
										</div>
										<div class="desc">ใช้สำหรับการซื้อสินค้า</div>
									</div>
									<div class="tip" @click="closeRewardPopup('balance')">ตรวจสอบ</div>
								</div>
							</div>
							<div class="reward-item" v-if="reward && reward.coupon_list.length > 0">
								<div class="head">คูปองรางวัล</div>
								<div class="content" v-for="(item, index) in reward.coupon_list" :key="index">
									<div class="info">
										<div>
											<span class="num coupon-name">{{ item.coupon_name }}</span>
										</div>
										<div class="desc" v-if="item.at_least > 0">
											ขั้นต่ำ {{ item.at_least }}{{ item.type == 'discount' ? 'ลด' + 100-(item.discount*10) + '%' : 'ลด' + item.money }}
										</div>
										<div class="desc" v-else>ไม่มีเกณฑ์，{{ item.type == 'discount' ? 'ลด' + 100-(item.discount*10) + '%' : 'ลด' + item.money }}</div>
									</div>
									<div class="tip" @click="closeRewardPopup('coupon')">ตรวจสอบ</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="close-btn" @click="closeRewardPopup()">
					<i class="iconfont icon-guanbi"></i>
				</div>
				
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getRegisiterAggrement,
		registerConfig,
		registerMobileCode,
		getRegisterReward
	} from '@/api/auth/register';
	import {
		captcha
	} from '@/api/website';
	export default {
		name: 'register',
		components: {},
		data() {
			var checkPassValidata = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('กรุณาใส่รหัสผ่านอีกครั้ง'));
				} else if (value !== this.registerForm.password) {
					callback(new Error('รหัสผ่านที่ป้อนสองครั้งไม่ตรงกัน!'));
				} else {
					callback();
				}
			};
			let self = this;
			var passwordValidata = function(rule, value, callback) {
				let regConfig = self.registerConfig;
				if (!value) {
					return callback(new Error('กรุณาใส่รหัสผ่าน'));
				} else {
					if (regConfig.pwd_len > 0) {
						if (value.length < regConfig.pwd_len) {
							return callback(new Error('ความยาวของรหัสผ่านต้องไม่น้อยกว่า' + regConfig.pwd_len + 'บิต'));
						} else {
							callback();
						}
					} else {
						if (regConfig.pwd_complexity != '') {
							let passwordErrorMsg = 'ต้องใส่รหัสผ่าน',
								reg = '';
							if (regConfig.pwd_complexity.indexOf('number') != -1) {
								reg += '(?=.*?[0-9])';
								passwordErrorMsg += 'เลข';
							} else if (regConfig.pwd_complexity.indexOf('letter') != -1) {
								reg += '(?=.*?[a-z])';
								passwordErrorMsg += '、b';
							} else if (regConfig.pwd_complexity.indexOf('upper_case') != -1) {
								reg += '(?=.*?[A-Z])';
								passwordErrorMsg += '、majuscule';
							} else if (regConfig.pwd_complexity.indexOf('symbol') != -1) {
								reg += '(?=.*?[#?!@$%^&*-])';
								passwordErrorMsg += '、อักขระพิเศษ';
							} else {
								reg += '';
								passwordErrorMsg += '';
							}

							if (reg.test(value)) {
								return callback(new Error(passwordErrorMsg));
							} else {
								callback();
							}
						}
					}
				}
			};
			var isMobile = (rule, value, callback) => {
				if (!value) {
					return callback(new Error("หมายเลขโทรศัพท์ไม่สามารถเว้นว่างได้"))
				} else {
					const reg = /((\+66|0)(\d{1,2}\-?\d{3}\-?\d{3,4}))|((\+๖๖|๐)([๐-๙]{1,2}\-?[๐-๙]{3}\-?[๐-๙]{3,4}))/

					if (reg.test(value)) {
						callback()
					} else {
						callback(new Error("โปรดป้อนหมายเลขโทรศัพท์มือถือที่ถูกต้อง"))
					}
				}
			};
			return {
				registerForm: {
					username: '',
					password: '',
					checkPass: '',
					code: '',
					mobile: '',
					vercode: '',
					dynacode: '',
					key: ''
				},
				registerRules: {
					username: [{
						required: true,
						message: 'กรุณาใส่ชื่อผู้ใช้',
						trigger: 'blur'
					}],
					password: [{
						required: true,
						validator: passwordValidata,
						trigger: 'blur'
					}],
					checkPass: [{
						required: true,
						validator: checkPassValidata,
						trigger: 'blur'
					}],
					code: [{
						required: true,
						message: 'กรุณากรอกรหัสยืนยัน',
						trigger: 'blur'
					}]
				},
				mobileRules: {
					mobile: [{
						required: true,
						validator: isMobile,
						trigger: "blur"
					}],
					vercode: [{
						required: true,
						message: "กรุณากรอกรหัสยืนยัน",
						trigger: "blur"
					}],
					dynacode: [{
						required: true,
						message: "กรุณาใส่รหัส SMS แบบไดนามิก",
						trigger: "blur"
					}]
				},
				dynacodeData: {
					seconds: 120,
					timer: null,
					codeText: "รับรหัสOTP",
					isSend: false
				}, // รหัสแบบไดนามิก
				ischecked: false,
				agreement: '',
				aggrementVisible: false,
				captcha: {
					// รหัสยืนยัน
					id: '',
					img: ''
				},
				registerConfig: {
					register: ''
				},
				activeName: "first", // tabการส่งมอบ
				reward: null,
				is_show: false,
				bgWidth: '',
				bgHeight: ''
			};
		},
		created() {
			this.getCode();
			this.regisiterAggrement();
			this.getRegisterConfig();
			this.getRegisterReward();
			this.bgWidth = document.documentElement.clientWidth + "px";
			this.bgHeight = document.documentElement.clientHeight + "px";
		},
		methods: {
			closeRewardPopup(type) {
				this.is_show = false;
			
				switch (type) {
					case 'point':
						this.$router.push('/member/my_point');
						break;
					case 'balance':
						this.$router.push('/member/account');
						break;
					case 'growth':
						this.$router.push('/member/index');
						break;
					case 'coupon':
						this.$router.push('/member/my_coupon');
						break;
					default:
						this.$router.push('/member/index');
						this.is_show = false;
				}
			},
			getRegisterReward() {
				getRegisterReward()
				.then(res=>{
					if (res.code >= 0) {
						let data = res.data;
						if (data.is_use == 1 && (data.value.point > 0 || data.value.balance > 0 || data.value.growth > 0 || data.value.coupon_list.length > 0 )) {
							this.reward = data.value;
						}
					}
					console.log(res,'getRegisterReward')
				})
				.catch(err=>{
					console.log(err,'getRegisterReward')
				})
			},
			sendMobileCode(formName) {
				if (this.dynacodeData.seconds != 120) return
				this.$refs[formName].clearValidate("dynacode")

				this.$refs[formName].validateField("mobile", valid => {
					if (valid) {
						return false
					}
				})
				this.$refs[formName].validateField("vercode", valid => {
					if (!valid) {
						registerMobileCode({
								mobile: this.registerForm.mobile,
								captcha_id: this.captcha.id,
								captcha_code: this.registerForm.vercode
							})
							.then(res => {
								if (res.code >= 0) {
									this.registerForm.key = res.data.key
									if (this.dynacodeData.seconds == 120 && this.dynacodeData.timer == null) {
										this.dynacodeData.timer = setInterval(() => {
											this.dynacodeData.seconds--
											this.dynacodeData.codeText = "รับอีกครั้ง "+this.dynacodeData.seconds + "s "
										}, 1000)
									}
								}
							})
							.catch(err => {
								this.$notify.error({title: 'Error',message: err.message});
							})
					} else {
						return false
					}
				})
			},
			handleClick(tab, event) {
				if (this.activeName == "first") {
					this.loginMode = "account"
				} else {
					this.loginMode = "mobile"
				}
			},
			check() {
				this.ischecked = !this.ischecked;
			},
			toLogin() {
				this.$router.push('/login');
			},
			//  รับการกําหนดค่าการลงทะเบียน
			getRegisterConfig() {
				registerConfig()
					.then(res => {
						if (res.code >= 0) {
							this.registerConfig = res.data.value;
							if (this.registerConfig.register == '') {
								if(this.registerConfig.third_party == 1){
									this.$notify({
										message: 'การลงทะเบียนปกติไม่ได้เปิดใช้งานสําหรับแพลตฟอร์ม',
										title: 'คำเตือน',type: 'warning',
										duration: 2000,
										onClose: () => {
											this.$router.push({ name: 'login', params:{ third_party: true } });
										}
									});
								}else{
									this.$notify({
										message: 'แพลตฟอร์มไม่ได้เปิดใช้งานการลงทะเบียน',
										title: 'คำเตือน',type: 'warning',
										duration: 2000,
										onClose: () => {
											this.$router.push('/');
										}
									});
								}
							}
						}
					})
					.catch(err => {
						console.log(err.message)
					});
			},
			// การลงทะเบียนรหัสผ่านบัญชี
			register() {
				this.$refs.registerRef.validate(valid => {
					if (valid) {
						if (!this.ischecked) {
							return this.$notify({
								message: 'โปรดอ่านข้อตกลงและติ๊กก่อน',
								title: 'คำเตือน',type: 'warning'
							});
						}

						var data = {
							username: this.registerForm.username.trim(),
							password: this.registerForm.password
						};
						
						var user_test = /^[A-Za-z0-9]+$/;
						if(!user_test.test(data.username)){
							return this.$notify({
								message: 'ชื่อผู้ใช้สามารถป้อนได้ด้วยตัวเลขตามด้วยภาษาอังกฤษเท่านั้น',
								title: 'คำเตือน',type: 'warning'
							});
						}
						
						if (this.captcha.id != '') {
							data.captcha_id = this.captcha.id;
							data.captcha_code = this.registerForm.code;
						}
						this.$store
							.dispatch('member/register_token', data)
							.then(res => {
								if (res.code >= 0) {
									if (this.reward) {
										this.is_show = true;
									} else {
										this.$router.push('/member/index');
									}
								}
							})
							.catch(err => {
								this.$notify.error({title: 'Error',message: err.message});
								this.getCode();
							});
					} else {
						return false;
					}
				});
			},
			// การลงทะเบียนหมายเลขโทรศัพท์มือถือ
			registerMobile() {
				this.$refs.mobileRuleForm.validate(valid => {
					if (valid) {
						if (!this.ischecked) {
							return this.$notify({
								message: 'โปรดอ่านข้อตกลงและติ๊กก่อน',
								title: 'คำเตือน',type: 'warning'
							});
						}
						var data = {
							mobile: this.registerForm.mobile,
							key: this.registerForm.key,
							code: this.registerForm.dynacode
						};
						if (this.captcha.id != '') {
							data.captcha_id = this.captcha.id;
							data.captcha_code = this.registerForm.code;
						}
						this.$store
							.dispatch('member/registerMobile_token', data)
							.then(res => {
								if (res.code >= 0) {
									if (this.reward) {
										this.is_show = true;
									} else {
										this.$router.push('/member/index');
									}
								}
							})
							.catch(err => {
								this.$notify.error({title: 'Error',message: err.message});
								this.getCode();
							});
					} else {
						return false;
					}
				});
			},
			aggrementClose() {
				this.aggrementVisible = false;
			},
			// รับโปรโตคอล
			regisiterAggrement() {
				getRegisiterAggrement()
					.then(res => {
						if (res.code >= 0) {
							this.agreement = res.data;
						}
					})
					.catch(err => {
						console.log(err.message)
					});
			},
			getAggrement() {
				this.aggrementVisible = true;
			},
			// รับรหัสยืนยัน
			getCode() {
				captcha({
						captcha_id: 'this.captcha.id'
					})
					.then(res => {
						if (res.code >= 0) {
							this.captcha = res.data;
							this.captcha.img = this.captcha.img.replace(/\r\n/g, '');
						}
					})
					.catch(err => {
						this.$notify.error({title: 'Error',message: err.message});
					});
			}
		}
	};
</script>
<style lang="scss" scoped>
	.register {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 20px 0;
	}

	.box-card {
		width: 550px;
		margin: 0 auto;
		display: flex;
		background-color: #ffffff;
		padding: 0 30px 30px 30px;
		flex-direction: column;
		padding-bottom: 0px;
		    margin-top: -112px;
		.register-title {
			border-bottom: 1px solid #f1f1f1;
			text-align: left;
			margin-bottom: 20px;
			font-size: 16px;
			color: $base-color;
			padding: 10px 0;
		}

		.register-account {
			width: 100%;
			text-align: center;
		}

		.code {
			width: 80%;
			text-align: left;
		}

		.el-form {
			margin: 0 30px;

			.captcha {
				vertical-align: top;
				max-width: inherit;
				max-height: 38px;
				line-height: 38px;
				cursor: pointer;
			}
		}

		.xyContent {
			height: 600px;
			overflow-y: scroll;
		}

		.xy {
			margin-left: 110px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			text-align: left;
			margin-right: 30px;

			.toLogin {
				cursor: pointer;
			}

			.xy-wrap {
				display: flex;
				align-items: center;
				font-size: $ns-font-size-base;
				cursor: pointer;

				.iconfont {
					display: flex;
					align-content: center;
				}

				.content {
					margin-left: 3px;

					b {
						color: $base-color;
					}
				}
			}

			.icon-xuanze-duoxuan {
				color: $base-color;
			}
		}

		.el-button {
			margin: 20px 0 0 25px;
			background-color: $base-color;
			color: #ffffff;
			width: calc(100% - 60px);
		}
	}
	
	.floatLayer-wrap{
		height: 100%;
		width: 100%;
		background-color: rgba(0,0,0,.5);
		z-index: 999;
		position: absolute;
		.reward-wrap {
			width: 400px;
			height: auto;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			.bg-img {
				width: 100%;
				will-change: transform;
			}
			.bg-img-head{
				position: absolute;
				top: -90px;
				width: 100%;
		
			}
			.bg-img-money{
				position: absolute;
				width: 100%;
				left: -20px;
				top: 80px;
				z-index: 10;
			}
		
			.wrap {
				width: calc(100% - 1px);
				height: 100%;
				background-color: #ef3030;
				margin-top: -40px;
				padding-bottom: 30px;
				border-bottom-left-radius: 5px;
				border-bottom-right-radius: 5px;
				& > div {
					position: relative;
				}
			}
		
			.reward-content {
				margin: 0 25px 0 25px;
			}
		
			.reward-item {
				.head {
					color: #fff;
					text-align: center;
					line-height: 1;
					margin: 10px 0;
				}
		
				.content {
					display: flex;
					padding: 8px 13px;
					background: #fff;
					border-radius: 5px;
					margin-bottom: 5px;
					.info {
						flex: 1;
					}
		
					.tip {
						color: #ff222d;
						padding: 5px 0 5px 15px;
						width: 70px;
						line-height: 1.5;
						letter-spacing: 1px;
						border-left: 1px dashed #e5e5e5;
						height: 40px;
						line-height: 40px;
					}
		
					.num {
						font-size: 26px;
						color: #ff222d;
						font-weight: bolder;
						line-height: 1;
					}
					.coupon-name{
						font-size: 19px;
					}
		
					.type {
						font-size: $ns-font-size-base;
						margin-left: 5px;
						line-height: 1;
					}
		
					.desc {
						margin-top: 4px;
						color: $base-color;
						font-size: $ns-font-size-base;
						line-height: 1;
					}
				}
			}
		
			.btn {
				position: absolute;
				width: calc(100% - 50px);
				bottom: 20px;
				left: 25px;
		
				.btn-img {
					width: 100%;
				}
			}
		}
	}
	.close-btn{
		text-align: center;
		margin-top: 20px;
		.iconfont{
			color: #fff;
			font-size: 20px;
		}
	}
	.clear{
		content: '';
		display: block;
		clear: both;
	}
	.floatLayer-wrap .reward-wrap .reward-item .content .tip{
	    color: #ff222d;
	    padding: 5px 0 5px 15px;
	    width: 70px;
	    line-height: 1.5 !important;
	    letter-spacing: 0px;
	    border-left: 1px dashed #e5e5e5;
	    height: 40px;
	    line-height: 40px;
	    font-weight: 500;
	}.floatLayer-wrap {
    height: 120% !important;
   
}.el-form-item__label {
    text-align: right;
    vertical-align: middle;
    float: left;
    font-size: 14px;
    color: #606266;
    line-height: 40px;
    padding: 0 12px 0 0;
    box-sizing: border-box;
    font-family: 'sukhumvittadmai';
}.el-input-group__append, .el-input-group__prepend {
        padding: 0 0px !important;
		width: 100px !important;
}
</style>
